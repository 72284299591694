import React from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiTypography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";

const Accordion = withStyles({
  root: {
    color: "inherit",
    fontFamily: "inherit",
    borderTop: "1px solid rgba(0, 0, 0, .250)",
    borderBottom: "1px solid rgba(0, 0, 0, .250)",
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:last-child": {
      borderBottom: "none",
    },
    "&:first-child": {
      borderTop: "none",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    textAlign: "left",
    backgroundColor: "transparent",
    borderBottom: "none)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expandIcon: { color: "inherit" },

  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    fontSize: "0.8rem",
  },
}))(MuiAccordionDetails);

const Typography = withStyles((theme) => ({
  root: {
    fontFamily: "inherit",
    fontSize: "inherit",
  },
}))(MuiTypography);

const AboutAccordion = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const { t } = useTranslation();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>{t("aboutUs.fullMilsim.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{t("aboutUs.fullMilsim.content")}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>{t("aboutUs.trueRoleplay.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{t("aboutUs.trueRoleplay.content")}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography>{t("aboutUs.modernApproach.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{t("aboutUs.modernApproach.content")}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography>{t("aboutUs.experiencedCadre.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{t("aboutUs.experiencedCadre.content")}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography>{t("aboutUs.notOnlyMarsoc.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{t("aboutUs.notOnlyMarsoc.content")}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography>{t("aboutUs.moreThanArma.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{t("aboutUs.moreThanArma.content")}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AboutAccordion;
