import React from "react";
import styles from "./Footer.module.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { buttonVariants } from "../../shared/animationVariants/itemVariants";

const Footer = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.FooterLeftSide}>
        <div>© Karol Cieślewicz 2022</div>
        <span>|</span>
        <NavLink to="/credits">Credits</NavLink>
      </div>
      <div className={styles.SocialMedia}>
        <a
          href="https://discord.gg/7Vp6C2GJRZ"
          target="_blank"
          rel="noreferrer"
        >
          <motion.div
            className={styles.SocialButton + " " + styles.DiscordLogo}
            variants={buttonVariants}
            whileHover="whileHover"
          ></motion.div>
        </a>
        <a
          href="https://www.youtube.com/c/ArmedVeteran/"
          target="_blank"
          rel="noreferrer"
        >
          <motion.div
            className={styles.SocialButton + " " + styles.YTLogo}
            variants={buttonVariants}
            whileHover="whileHover"
          ></motion.div>
        </a>
        <a
          href="https://www.facebook.com/MaritimeAssault"
          target="_blank"
          rel="noreferrer"
        >
          <motion.div
            className={styles.SocialButton + " " + styles.FBLogo}
            variants={buttonVariants}
            whileHover="whileHover"
          ></motion.div>
        </a>
        <a
          href="https://www.instagram.com/maritimeassault/"
          target="_blank"
          rel="noreferrer"
        >
          <motion.div
            className={styles.SocialButton + " " + styles.IGLogo}
            variants={buttonVariants}
            whileHover="whileHover"
          ></motion.div>
        </a>
        <a
          href="https://twitter.com/maritimeassault"
          target="_blank"
          rel="noreferrer"
        >
          <motion.div
            className={styles.SocialButton + " " + styles.TTLogo}
            variants={buttonVariants}
            whileHover="whileHover"
          ></motion.div>
        </a>
        <a
          href="https://www.tiktok.com/@maritimeassault"
          target="_blank"
          rel="noreferrer"
        >
          <motion.div
            className={styles.SocialButton + " " + styles.TikTokLogo}
            variants={buttonVariants}
            whileHover="whileHover"
          ></motion.div>
        </a>
      </div>
    </div>
  );
};

export default Footer;
