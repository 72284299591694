import React, { useEffect, useState } from "react";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import { fetchCalendarEvents } from "../../utils/fetchCalendarEvents";

import "./EventCalendar.scss";
import { useTranslation } from "react-i18next";

require("moment/locale/pl.js");
const localizer = momentLocalizer(moment);

const EventCalendar = () => {
  const [events, setEvents] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchCalendarEvents((eventList) => {
      setEvents(eventList);
    });
  }, []);

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        messages={{
          next: t("calendar.next"),
          previous: t("calendar.previous"),
          today: t("calendar.today"),
          month: t("calendar.month"),
          week: t("calendar.week"),
          day: t("calendar.day"),
          agenda: t("calendar.agenda"),
          date: t("calendar.date"),
          time: t("calendar.time"),
          event: t("calendar.event"),
        }}
      />
    </div>
  );
};

export default EventCalendar;
