import React, { useEffect, useState } from "react";
import Toolbar from "../../components/Navigation/Toolbar";
import Footer from "../../components/Footer/Footer";
import styles from "./Layout.module.css";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
import { useLocation } from "react-router";

const Layout = (props) => {
  const [showSideDrawer, toggleShowSideDrawer] = useState(false);

  const location = useLocation();

  useEffect(() => {
    sideDrawerClosedHandler();
  }, [location.pathname]);

  const sideDrawerClosedHandler = () => {
    toggleShowSideDrawer(false);
  };

  const sideDrawerToggleHandler = () => {
    toggleShowSideDrawer((prevState) => !prevState);
  };

  return (
    <>
      <Toolbar drawerToggleClicked={sideDrawerToggleHandler} />
      <SideDrawer open={showSideDrawer} closed={sideDrawerClosedHandler} />
      <main className={styles.Layout}>{props.children}</main>
      <Footer />
    </>
  );
};

export default Layout;
