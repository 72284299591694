import React from "react";
import styles from "./RecruitmentCarousel.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { motion } from "framer-motion";
import { linkVariants } from "../../shared/animationVariants/itemVariants";
import { useTranslation } from "react-i18next";

const RecruitmentCarousel = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.RecruitmentCarousel}>
      <Carousel
        showThumbs={false}
        useKeyboardArrows
        dynamicHeight
        swipeable
        emulateTouch
        statusFormatter={(current, total) => `${current} / ${total}`}
      >
        <div className={styles.TextDiv}>
          <h3>{t("recruitment.requirements.header")}</h3>
          <span>
            <div className={styles.ListHeader}>
              {t("recruitment.requirements.content.text")}
            </div>
            <ul className={styles.Requirements}>
              <li>
                <span>{t("recruitment.requirements.content.p1")}</span>
              </li>
              <li>
                <span>{t("recruitment.requirements.content.p2")}</span>
              </li>
              <li>
                <span>{t("recruitment.requirements.content.p3")}</span>
              </li>
              <li>
                <span>{t("recruitment.requirements.content.p4")}</span>
              </li>
              <li>
                <span>{t("recruitment.requirements.content.p5")}</span>
              </li>
              <li>
                <span>{t("recruitment.requirements.content.p6")}</span>
              </li>
            </ul>
          </span>
        </div>
        <div className={styles.TextDiv}>
          <h3>{t("recruitment.form.header")}</h3>
          <span>{t("recruitment.form.content")}</span>
          <br />
          <motion.div
            className={styles.TextLinkWrapper}
            variants={linkVariants}
            whileHover="whileHover"
          >
            <a
              href="https://forms.gle/7zSHzD2Do4MmiRX69"
              target="_blank"
              rel="noreferrer"
            >
              &#x1F449; {t("recruitment.form.buttonText")} &#x1F448;
            </a>
          </motion.div>
        </div>
        <div className={styles.TextDiv}>
          <h3>{t("recruitment.discord.header")}</h3>
          <span>{t("recruitment.discord.content")}</span>
          <br />
          <motion.div
            className={styles.TextLinkWrapper}
            variants={linkVariants}
            whileHover="whileHover"
          >
            <a
              href="https://discord.gg/7Vp6C2GJRZ"
              target="_blank"
              rel="noreferrer"
            >
              &#x1F449; {t("recruitment.discord.buttonText")} &#x1F448;
            </a>
          </motion.div>
        </div>
        <div className={styles.TextDiv}>
          <h3>{t("recruitment.bct.header")}</h3>
          <span>{t("recruitment.bct.content.p1")}</span>
        </div>
      </Carousel>
    </div>
  );
};

export default RecruitmentCarousel;
