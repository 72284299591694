import React, { useEffect, useRef } from "react";
import styles from "./MainPage.module.css";
import Parallax from "parallax-js";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { motion } from "framer-motion";
import containerVariants from "../../shared/animationVariants/containerVariants";
import { mainTextBoxVariants } from "../../shared/animationVariants/itemVariants";
import { useTranslation } from "react-i18next";

const MainPage = (props) => {
  const sceneEl = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      scalarX: 15,
      limitY: 50,
    });
    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  return (
    <motion.div
      className={styles.MainPage}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <ul className={styles.Scene} ref={sceneEl}>
        <li className={styles.Layer} data-depth="0.2">
          <div className={styles.MainBackground}></div>
        </li>
        <li className={styles.Layer} data-depth="0.23">
          <div
            className={styles.MainBackground + " " + styles.MainLayer2}
          ></div>
        </li>
        <li className={styles.Layer} data-depth="0.25">
          <div
            className={styles.MainBackground + " " + styles.MainLayer1}
          ></div>
        </li>
        <li className={styles.Layer} data-depth="0.4">
          <div
            className={styles.MainBackground + " " + styles.MainLayer0}
          ></div>
        </li>
      </ul>
      <div className={styles.ContentWrapper}>
        <motion.div
          className={styles.MainTextBox}
          variants={mainTextBoxVariants}
        >
          <h1>Maritime Assault Group</h1>
          <ul>
            <li>
              <ArrowForwardIosIcon />
              <span>{t("main.fullMilsim")}</span>
            </li>
            <li>
              <ArrowForwardIosIcon />
              <span>{t("main.trueRoleplay")}</span>
            </li>
            <li>
              <ArrowForwardIosIcon />
              <span>{t("main.modernApproach")}</span>
            </li>
          </ul>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default MainPage;
