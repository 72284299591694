import React, { useEffect, useRef, useState } from "react";
import Parallax from "parallax-js";
import styles from "./Recruitment.module.css";
import RecruitmentCarousel from "../../components/RecruitmentCarousel/RecruitmentCarousel";
import containerVariants from "../../shared/animationVariants/containerVariants";
import { motion } from "framer-motion";
import { mainTextBoxVariants } from "../../shared/animationVariants/itemVariants";
import RecruitmentStatus from "../../components/UI/RecruitmentStatus/RecruitmentStatus";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Recruitment = (props) => {
  const sceneEl = useRef(null);
  const [recruitmentStatus, setRecruitmentStatus] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      scalarX: 15,
      limitY: 50,
    });
    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://mag-website-26fe8-default-rtdb.europe-west1.firebasedatabase.app/recruitment-status.json"
      )
      .then((response) => setRecruitmentStatus(response.data))
      .catch((err) => {});
  }, []);

  return (
    <motion.div
      className={styles.Recruitment}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <ul className={styles.Scene} ref={sceneEl}>
        <li className={styles.Layer} data-depth="0.2">
          <div className={styles.RecruitmentBackground}></div>
        </li>
        <li className={styles.Layer} data-depth="0.22">
          <div
            className={
              styles.RecruitmentBackground + " " + styles.RecruitmentLayer2
            }
          ></div>
        </li>
        <li className={styles.Layer} data-depth="0.35">
          <div
            className={
              styles.RecruitmentBackground + " " + styles.RecruitmentLayer1
            }
          ></div>
        </li>
        <li className={styles.Layer} data-depth="0.4">
          <div
            className={
              styles.RecruitmentBackground + " " + styles.RecruitmentLayer0
            }
          ></div>
        </li>
      </ul>
      <div className={styles.ContentWrapper}>
        <motion.div
          className={styles.MainTextBox}
          variants={mainTextBoxVariants}
        >
          <h1>{t("recruitment.title")}</h1>
          <RecruitmentStatus status={recruitmentStatus} />
          <RecruitmentCarousel />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Recruitment;
