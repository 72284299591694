import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import Parallax from "parallax-js";
import styles from "./Credits.module.css";
import containerVariants from "../../shared/animationVariants/containerVariants";
import { mainTextBoxVariants } from "../../shared/animationVariants/itemVariants";

const Credits = () => {
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      scalarX: 15,
      limitY: 50,
    });
    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  return (
    <motion.div
      className={styles.Credits}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <ul className={styles.Scene} ref={sceneEl}>
        <li className={styles.Layer} data-depth="0.2">
          <div className={styles.CreditsBackground}></div>
        </li>
      </ul>
      <div className={styles.ContentWrapper}>
        <motion.div
          className={styles.MainTextBox}
          variants={mainTextBoxVariants}
        >
          <h1>About the project and other creators credits</h1>
          <ul>
            <li>Website based on React framework</li>
            <li>
              Carousels by{" "}
              <a href="https://www.npmjs.com/package/react-responsive-carousel">
                react-responsive-carousel
              </a>
            </li>
            <li>
              Calendar by{" "}
              <a href="http://jquense.github.io/react-big-calendar/examples/index.html">
                react-big-calendar
              </a>
            </li>
            <li>
              Animations by{" "}
              <a href="https://www.framer.com/motion/">Framer Motion</a>
            </li>
            <li>
              Parallax effect from{" "}
              <a href="https://matthew.wagerfield.com/parallax/">
                parallax-js by Matthew Wagerfield
              </a>
            </li>
            <li>
              Various UI items by{" "}
              <a href="https://material-ui.com/">Material-UI</a>
            </li>
            <li>
              Social media icons by{" "}
              <a href="https://www.freepik.com/flatart">flatart</a>
            </li>
            <li>
              Logo vector created by{" "}
              <a href="https://www.freepik.com/dgim-studio">dgim-studio</a>
            </li>
            <li>
              Thanks for the help with translation to Executor and JJayRex
            </li>
          </ul>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Credits;
