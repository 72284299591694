import React, { useEffect, useState } from "react";
import styles from "./Toolbar.module.scss";
import NavigationItems from "./NavigationItems/NavigationItems";
import Logo from "../UI/Logo/Logo";
import { NavLink, useLocation } from "react-router-dom";
import DrawerToggle from "./SideDrawer/DrawerToggle/DrawerToggle";
import backgroundBrightness from "../../shared/config/backgroundBrightness";

const Toolbar = (props) => {
  const [classes, setClasses] = useState([styles.LogoTextContainer]);
  const location = useLocation();

  useEffect(() => {
    if (backgroundBrightness.light.includes(location.pathname)) {
      setClasses([...classes, styles.IncreasedVisibilty]);
    } else {
      setClasses([styles.LogoTextContainer]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className={styles.Toolbar}>
      <DrawerToggle clicked={props.drawerToggleClicked} />
      <NavLink to="/" className={styles.LogoLink}>
        <Logo customClass={styles.Logo} />
        <div className={classes.join(" ")}>
          <span className={styles.glitch} data-text="MARITIME">
            MARITIME
          </span>
          <span className={styles.glitch} data-text="ASSAULT">
            ASSAULT
          </span>
          <span className={styles.glitch} data-text="GROUP">
            GROUP
          </span>
        </div>
      </NavLink>
      <nav className={styles.DesktopOnly}>
        <NavigationItems />
      </nav>
    </div>
  );
};

export default Toolbar;
