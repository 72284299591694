import { motion } from "framer-motion";
import React from "react";

import logoImg from "../../../assets/logo.png";
import { logoVariants } from "../../../shared/animationVariants/itemVariants";
import styles from "./Logo.module.css";

const Logo = (props) => {
  return (
    <motion.div
      className={styles.Logo + " " + props.customClass}
      variants={logoVariants}
      whileHover="whileHover"
    >
      <img src={logoImg} alt="MAG Logo" />
    </motion.div>
  );
};

export default Logo;
