import React, { useEffect, useRef } from "react";
import Parallax from "parallax-js";
import styles from "./Team.module.css";
import TeamCarousel from "../../components/TeamCarousel/TeamCarousel";
import { motion } from "framer-motion";
import containerVariants from "../../shared/animationVariants/containerVariants";
import { mainTextBoxVariants } from "../../shared/animationVariants/itemVariants";
import { useTranslation } from "react-i18next";

const Team = () => {
  const sceneEl = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      scalarX: 15,
      limitY: 50,
    });
    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  return (
    <motion.div
      className={styles.Team}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <ul className={styles.Scene} ref={sceneEl}>
        <li className={styles.Layer} data-depth="0.2">
          <div className={styles.TeamBackground}></div>
        </li>
        <li className={styles.Layer} data-depth="0.24">
          <div
            className={styles.TeamBackground + " " + styles.TeamLayer2}
          ></div>
        </li>
        <li className={styles.Layer} data-depth="0.26">
          <div
            className={styles.TeamBackground + " " + styles.TeamLayer1}
          ></div>
        </li>
        <li className={styles.Layer} data-depth="0.4">
          <div
            className={styles.TeamBackground + " " + styles.TeamLayer0}
          ></div>
        </li>
      </ul>
      <div className={styles.ContentWrapper}>
        <motion.div
          className={styles.MainTextBox}
          variants={mainTextBoxVariants}
        >
          <h1>{t("team.title")}</h1>
          <TeamCarousel />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Team;
