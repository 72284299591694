const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { type: "tween", delay: 0.1, duration: 1 },
    when: "beforeChildren",
  },
  exit: {
    opacity: 0,
    transition: { ease: "easeInOut" },
  },
};

export default containerVariants;
