export const buttonVariants = {
  whileHover: { scale: 1.6, transition: { type: "tween" } },
};

export const linkVariants = {
  whileHover: {
    scale: [1, 1.3, 1],
    transition: { type: "tween", repeat: Infinity, duration: 1 },
  },
};

export const logoVariants = {
  whileHover: {
    scale: 1.2,
    transition: { type: "tween", duration: 0.5 },
  },
};

export const mainTextBoxVariants = {
  hidden: {
    opacity: 0,
    // filter: "blur(10px)",
  },

  visible: {
    opacity: 1,
    // filter: "blur(0px)",
    transition: { type: "tween", duration: 1, delay: 0.5 },
  },
};

export const navigationItemVariants = {
  whileHover: {
    scale: 1.1,
    originY: 0.2,
    transition: { type: "tween" },
  },
};
