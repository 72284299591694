import React from "react";
import styles from "./TeamCarousel.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Avatar from "@material-ui/core/Avatar";
import AvatarJand34 from "../../assets/jand34.jpg";
import AvatarArmedVeteran from "../../assets/avv4.jpg";
import AvatarEgzekutor from "../../assets/egzekutor.jpg";
import AvatarCookie from "../../assets/cookie.jpg";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  large: {
    width: "4rem",
    height: "4rem",
    margin: "auto",
  },
}));

const TeamCarousel = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.TeamCarousel}>
      <Carousel
        showThumbs={false}
        useKeyboardArrows
        dynamicHeight
        swipeable
        emulateTouch
        statusFormatter={(current, total) => `${current} / ${total}`}
      >
        <div className={styles.TextDiv}>
          <Avatar alt="Jand34" src={AvatarJand34} className={classes.large} />
          <h3>Jand34</h3>
          <span>
            <ul className={styles.Requirements}>
              <li>
                <span>{t("team.jand.list.p1")}</span>
              </li>
              <li>
                <span>{t("team.jand.list.p2")}</span>
              </li>
              <li>
                <span>{t("team.jand.list.p3")}</span>
              </li>
              <li>
                <span>{t("team.jand.list.p4")}</span>
              </li>
              <li>
                <span>{t("team.jand.list.p5")}</span>
              </li>
            </ul>
            {t("team.jand.description")}
          </span>
        </div>
        <div className={styles.TextDiv}>
          <Avatar
            alt="ArmedVeteran"
            src={AvatarArmedVeteran}
            className={classes.large}
          />
          <h3>ArmedVeteran</h3>
          <span>
            <ul className={styles.Requirements}>
              <li>
                <span>{t("team.armedveteran.list.p1")}</span>
              </li>
              <li>
                <span>{t("team.armedveteran.list.p2")}</span>
              </li>
              <li>
                <span>{t("team.armedveteran.list.p3")}</span>
              </li>
              <li>
                <span>{t("team.armedveteran.list.p4")}</span>
              </li>
              <li>
                <span>{t("team.armedveteran.list.p5")}</span>
              </li>
            </ul>
            {t("team.armedveteran.description")}
          </span>
        </div>
        <div className={styles.TextDiv}>
          <Avatar
            alt="Egzekutor"
            src={AvatarEgzekutor}
            className={classes.large}
          />
          <h3>Egzekutor</h3>
          <span>
            <ul className={styles.Requirements}>
              <li>
                <span>{t("team.egzekutor.list.p1")}</span>
                <span>{t("team.egzekutor.list.p2")}</span>
              </li>
            </ul>
            {t("team.egzekutor.description")}
          </span>
        </div>
        <div className={styles.TextDiv}>
          <Avatar
            alt="Cookie_Monster"
            src={AvatarCookie}
            className={classes.large}
          />
          <h3>Cookie_Monster</h3>
          <span>
            <ul className={styles.Requirements}>
              <li>
                <span>{t("team.cookie.list.p1")}</span>
                <span>{t("team.cookie.list.p2")}</span>
              </li>
            </ul>
            {t("team.cookie.description")}
          </span>
        </div>
      </Carousel>
    </div>
  );
};

export default TeamCarousel;
