import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import NavigationItem from "./NavigationItem/NavigationItem";
import styles from "./NavigationItems.module.css";
import backgroundBrightness from "../../../shared/config/backgroundBrightness";
import FlagButton from "./FlagButton/FlagButton";
import { useTranslation } from "react-i18next";

const NavigationItems = (props) => {
  const [classes, setClasses] = useState([styles.NavigationItems]);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (backgroundBrightness.light.includes(location.pathname)) {
      setClasses([...classes, styles.IncreasedVisibilty]);
    } else {
      setClasses([styles.NavigationItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ul className={classes.join(" ")}>
      <NavigationItem to="/" exact>
        {t("navigation.mainPage")}
      </NavigationItem>
      <span>/</span>
      <NavigationItem to="/o-nas">{t("navigation.about")}</NavigationItem>{" "}
      <span>/</span>
      <NavigationItem to="/rekrutacja">
        {t("navigation.recruitment")}
      </NavigationItem>{" "}
      <span>/</span>
      <NavigationItem to="/kalendarz">
        {t("navigation.calendar")}
      </NavigationItem>{" "}
      <span>/</span>
      <NavigationItem to="/zespol">{t("navigation.team")}</NavigationItem>{" "}
      <span>/</span>
      <FlagButton />
    </ul>
  );
};

export default NavigationItems;
