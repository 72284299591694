import { motion } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import flagPL from "../../../../assets/flagPL.png";
import flagUS from "../../../../assets/flagUS.png";
import { navigationItemVariants } from "../../../../shared/animationVariants/itemVariants";
import styles from "./FlagButton.module.css";

const FlagButton = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = () => {
    if (language === "pl") {
      setLanguage("en");
      i18n.changeLanguage("en");
    } else {
      setLanguage("pl");
      i18n.changeLanguage("pl");
    }
  };

  let flag = null;

  switch (i18n.language) {
    case "pl":
    case "pl-PL":
      flag = flagUS;
      break;
    default:
      flag = flagPL;
  }

  return (
    <motion.li
      className={styles.NavigationItem}
      variants={navigationItemVariants}
      whileHover="whileHover"
    >
      <button onClick={changeLanguage} className={styles.FlagButton}>
        <img src={flag} alt="flag" />
      </button>
    </motion.li>
  );
};

export default FlagButton;
