import React from "react";
import styles from "./NavigationItem.module.css";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { navigationItemVariants } from "../../../../shared/animationVariants/itemVariants";

const NavigationItem = (props) => {
  return (
    <motion.li
      className={styles.NavigationItem + " " + props.customClass}
      variants={navigationItemVariants}
      whileHover="whileHover"
    >
      <NavLink
        to={props.to}
        exact={props.exact}
        activeClassName={styles.active}
      >
        {props.children}
      </NavLink>
    </motion.li>
  );
};

export default NavigationItem;
