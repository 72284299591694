import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import Parallax from "parallax-js";
import containerVariants from "../../shared/animationVariants/containerVariants";
import styles from "./Calendar.module.css";
import EventCalendar from "../../components/EventCalendar/EventCalendar";
import { mainTextBoxVariants } from "../../shared/animationVariants/itemVariants";

const Calendar = () => {
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      scalarX: 15,
      limitY: 50,
    });
    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  return (
    <motion.div
      className={styles.Calendar}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <ul className={styles.Scene} ref={sceneEl}>
        <li className={styles.Layer} data-depth="0.2">
          <div className={styles.CalendarBackground}></div>
        </li>
      </ul>
      <div className={styles.ContentWrapper}>
        <motion.div
          className={styles.MainTextBox}
          variants={mainTextBoxVariants}
        >
          <EventCalendar />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Calendar;
