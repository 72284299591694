import React, { useEffect, useRef } from "react";
import Parallax from "parallax-js";
import styles from "./About.module.css";
import AboutAccordion from "../../components/AboutAccordion/AboutAccordion";
import { motion } from "framer-motion";
import containerVariants from "../../shared/animationVariants/containerVariants";
import { mainTextBoxVariants } from "../../shared/animationVariants/itemVariants";
import { useTranslation } from "react-i18next";

const About = () => {
  const sceneEl = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      scalarX: 15,
      limitY: 50,
    });
    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  return (
    <motion.div
      className={styles.About}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <ul className={styles.Scene} ref={sceneEl}>
        <li className={styles.Layer} data-depth="0.2">
          <div className={styles.AboutBackground}></div>
        </li>
        <li className={styles.Layer} data-depth="0.35">
          <div
            className={styles.AboutBackground + " " + styles.AboutLayer0}
          ></div>
        </li>
      </ul>
      <div className={styles.ContentWrapper}>
        <motion.div
          className={styles.MainTextBox}
          variants={mainTextBoxVariants}
        >
          <h1>{t("aboutUs.title")}</h1>
          <AboutAccordion />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default About;
