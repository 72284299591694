import axios from "axios";
import moment from "moment";

const CALENDAR_ID = "maritimeassault@gmail.com";
const API_KEY = "AIzaSyCbBwEXKYhLCnuYlwPDdajdgW1j42Au0pc";
let url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}`;

export function fetchCalendarEvents(callback) {
  axios
    .get(url)
    .then((res) => {
      const events = [];
      res.data.items.map((event) => {
        if (event.start.dateTime) {
          events.push({
            start: new Date(event.start.dateTime),

            end: new Date(event.end.dateTime),
            title: event.summary,
          });
        } else {
          events.push({
            start: moment(event.start.date, "YYYY-MM-DD").toDate(),

            end: moment(event.end.date, "YYYY-MM-DD").toDate(),
            title: event.summary,
          });
        }

        return null;
      });
      callback(events);
    })
    .catch((err) => {
      console.log(err);
    });
}

//2021-02-10T10:30:00+01:00

//   start: event.start.date || event.start.dateTime,
//   end: event.end.date || event.end.dateTime,
