import React from "react";
import { useTranslation } from "react-i18next";

const RecruitmentStatus = (props) => {
  const { status } = props;
  const { t } = useTranslation();

  const statusOutput = status ? (
    <span style={{ color: "green", fontWeight: "bold" }}>
      {t("recruitment.recruitmentStatus.open")}
    </span>
  ) : (
    <span style={{ color: "red", fontWeight: "bold" }}>
      {t("recruitment.recruitmentStatus.closed")}
    </span>
  );
  return (
    <p>
      {t("recruitment.recruitmentStatus.title")} {statusOutput}
    </p>
  );
};

export default RecruitmentStatus;
