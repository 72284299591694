import Layout from "./hoc/Layout/Layout";
import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import MainPage from "./containers/MainPage/MainPage";
import About from "./containers/About/About";
import Recruitment from "./containers/Recruitment/Recruitment";
import Calendar from "./containers/Calendar/Calendar";
import Team from "./containers/Team/Team";
import Credits from "./containers/Credits/Credits";
import { AnimatePresence, motion } from "framer-motion";
import Spinner from "./components/UI/Spinner/Spinner";
import styles from "./App.module.css";
import containerVariants from "./shared/animationVariants/containerVariants";
import { Suspense } from "react";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const cacheImages = async (srcObject) => {
    let promises = [];

    for (const [, value] of Object.entries(srcObject)) {
      promises.push(
        new Promise(function (resolve, reject) {
          const img = new Image();
          img.src = value.default;
          img.onload = resolve();
          img.onerror = reject();
        })
      );
    }

    await Promise.all(promises);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const importAll = (r) => {
      let images = {};
      r.keys().map((item, index) => {
        images[item.replace("./", "")] = r(item);
        return null;
      });
      return images;
    };

    const imgs = importAll(require.context("./assets", false));
    cacheImages(imgs);
  }, []);

  return (
    <>
      <Suspense
        fallback={
          <motion.div
            className={styles.SpinnerWrapper}
            variants={containerVariants}
            exit="exit"
          >
            <h1 className={styles.TextHeader}>Maritime Assault Group</h1>
            <Spinner />
          </motion.div>
        }
      >
        <AnimatePresence>
          {isLoading ? (
            <motion.div
              className={styles.SpinnerWrapper}
              variants={containerVariants}
              exit="exit"
            >
              <h1 className={styles.TextHeader}>Maritime Assault Group</h1>
              <Spinner />
            </motion.div>
          ) : (
            <Layout>
              <Switch location={location} key={location.key}>
                <Route path="/" exact component={MainPage} />
                <Route path="/o-nas" component={About} />
                <Route path="/rekrutacja" component={Recruitment} />
                <Route path="/kalendarz" component={Calendar} />
                <Route path="/zespol" component={Team} />
                <Route path="/credits" component={Credits} />
              </Switch>
            </Layout>
          )}
        </AnimatePresence>
      </Suspense>
    </>
  );
}

export default App;
